<template>
  <div>
    <div v-if="!IsDashboard" style="text-align: center">
      <interval-manager
        :title="$t('module.charts.' + Chart.Name)"
        :interval="interval"
        :currentDate="currentDate"
        :currentDateText="currentDateText"
        @intervalChanged="intervalChanged"
      />
    </div>
    <ejs-chart
      :id="'bodyweight-chart-component' + Id"
      :primaryXAxis="xAxis"
      :primaryYAxis="yAxis"
      :palettes="palettes"
      :tooltip="IsDashboard ? {} : tooltip"
      background="transparent"
      width="100%"
      :title="IsDashboard ? $t('module.charts.' + Chart.Name) : ''"
      :titleStyle="IsDashboard ? titleStyle : {}"
      :height="IsDashboard ? '185px' : '90%'"
      isResponsive="true"
    >
      <e-series-collection>
        <e-series
          v-if="chartType == $enums.DiagramType.chart"
          :dataSource="chartData"
          type="Column"
          :xName="Chart.DataProperties.chart.x"
          :yName="Chart.DataProperties.chart.y"
          :marker="IsDashboard ? {} : marker"
        ></e-series>
        <e-series
          v-else-if="chartType == $enums.DiagramType.diary"
          :dataSource="chartData"
          type="Column"
          :xName="Chart.DataProperties.diary.x"
          :yName="Chart.DataProperties.diary.y"
          :marker="marker"
        ></e-series>
      </e-series-collection>
    </ejs-chart>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import {
  ChartPlugin,
  ColumnSeries,
  DateTime,
  Tooltip,
  StripLine,
} from "@syncfusion/ej2-vue-charts";
import { DiaryLogic } from "../../../../../Logic/Backend/diary";
import { ChartUiLogic } from "@/Logic/Ui/chart-ui";
import { HealthPlanLogic } from "../../../../../Logic/Backend/health-plan.js";
import IntervalManager from "../Components/IntervalManager.vue";
import { ChartBackendLogic } from '../../../../../Logic/Backend/chart';

Vue.use(ChartPlugin);

export default {
  components: { IntervalManager },
  name: "ExerciseCalories",
  data() {
    return {
      chartData: [],
      currentDate: moment(),
      currentDateText: null,
      interval: null,
      chartType: null,
      //Chart paraméterek
      xAxis: {
        title: this.IsDashboard ? null : this.$t("base.calendar.date"),
        valueType: "DateTime",
        interval: this.IsDashboard ? 5 : null,
        intervalType: "Days",
        labelFormat: "MM-dd",
        labelIntersectAction: "Rotate45",
      },
      yAxis: {
        title: this.IsDashboard ? null : this.$t("module.charts.weight"),
        valueType: "Double",
        labelFormat: "0 " + this.Chart.DisplayUnit,
        minimum: 0,
      },
      palettes: ["var(--eh-secondary-5)"],
      marker: {
        visible: true,
      },
      tooltip: {
        enable: true,
        header: "Testtömeg",
        format: "${point.x} : <b>${point.y}</b>",
        textStyle: {
          fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        },
      },
      titleStyle: {
        fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        fontWeight: "bold",
        color: "var(--eh-secondary-5)",
      },
    };
  },
  props: {
    ChartType: String, //diary, chart
    PatientId: Number,
    IsDashboard: Boolean,
    Interval: String,
    Chart: Object,
    Id: [String, Number],
  },
  provide: {
    chart: [ColumnSeries, DateTime, Tooltip, StripLine],
  },
  methods: {
    setInterval() {
      const result = ChartUiLogic.IntervalManager.setInterval(
        this.interval,
        this.xAxis,
        this.currentDate
      );
      this.currentDate = result.currentDate;
      this.currentDateText = result.currentDateString;
      this.xAxis = result.xAxis;
      //fillEmptyDays
    },
    async intervalChanged(currentDate, interval) {
      this.currentDate = currentDate;
      this.interval = interval;
      if (interval == this.$enums.ChartInterval.Day.Value) {
        this.chartType = this.$enums.DiagramType.diary;
      } else {
        this.chartType = this.ChartType;
      }
      await this.getData();
      this.setInterval();
    },
    async getData() {
      if (this.chartType == this.$enums.DiagramType.chart) {
        await this.getChartData();
      } else if (this.chartType == this.$enums.DiagramType.diary) {
        await this.getDiaryData();
      }
      this.getPlan();
    },
    async getChartData() {
      var responseChartData = null;
      if (this.PatientId) {
        responseChartData = await ChartBackendLogic.getPatientChart(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToChart()
        );
      } else {
        responseChartData = await ChartBackendLogic.getChart(
          this.Chart.DatabaseName,
          this.getIntervalParamsToChart()
        );
      }
      if (!responseChartData.Code) {
        this.chartData = responseChartData;
        this.fillEmptyDays(this.Chart.DataProperties.chart.x, {
          [this.Chart.DataProperties.chart.y]: null,
        });
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getDiaryData() {
      var responseChartData = null;
      if (this.PatientId) {
        responseChartData = await DiaryLogic.getPatientDiary(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToDiary()
        );
      } else {
        responseChartData = await DiaryLogic.getDiary(
          this.Chart.DatabaseName,
          this.getIntervalParamsToDiary()
        );
      }
      if (!responseChartData.Code) {
        this.chartData = responseChartData;
        this.fillEmptyDays(this.Chart.DataProperties.diary.x, {
          [this.Chart.DataProperties.diary.y]: null,
        });
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //terv lista kérés
    async getPlan() {
      //van beteg id?
      if (this.PatientId) {
        //beteg terv adatok betöltése
        await this.getPatientPlanData();
      } else {
        //saját terv adatok betöltése
        await this.getPlanData();
      }
    },
    //beteg terv adatok kérés
    async getPatientPlanData() {
      //console.log("Patient ExerciseSteps Plan");
    },
    //saját terv adatok kérés
    async getPlanData() {
      //kérés indítása
      let getPlanDataResponse = await HealthPlanLogic.getHealthPlanList(
        this.Chart.DisplayPlan.planType,
        {
          Status: "InProgress",
          Type: this.Chart.DisplayPlan.planModuleType,
        }
      );
      //sikeres kérés?
      if (!getPlanDataResponse.Code) {
        //kapott lista nem nulla elemű?
        if (getPlanDataResponse.length > 0) {
          //console.log("van plan");
          //nem, terv érték tárolása
          var planStartValue = getPlanDataResponse[0].StartValue;
          var planTargetValue = getPlanDataResponse[0].TargetValue;
          //trev megjelenítő tároló feltöltése
          if (this.IsDashboard) {
            Vue.set(this.yAxis, "stripLines", [
              {
                start: planStartValue,
                size: 2,
                sizeType: "Pixel",
                color: "blue",
              },
              {
                start: planTargetValue,
                size: 2,
                sizeType: "Pixel",
                color: "green",
              },
            ]);
          } else {
            Vue.set(this.yAxis, "stripLines", [
              {
                start: planStartValue + 0.1,
                size: 2,
                sizeType: "Pixel",
                color: "blue",
                text: "Kezdeti érték",
                verticalAlignment: "End",
              },
              {
                start: planTargetValue - 0.1,
                size: 2,
                sizeType: "Pixel",
                color: "green",
                text: "Cél érték",
                verticalAlignment: "Start",
              },
            ]);
          }
        }
      } else {
        this.$bvToast.toast(getPlanDataResponse.Message, {
          title: this.$t("requestResponse.moduleHealthGoal.errorGet", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    getIntervalParamsToDiary() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToDiary(
        this.interval,
        this.currentDate
      );
    },
    getIntervalParamsToChart() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToChart(
        this.interval,
        this.currentDate
      );
    },
    fillEmptyDays(datetimeName, value) {
      var lastElement = this.chartData[this.chartData.length - 1];
      if (lastElement) {
        this.chartData.push({
          [datetimeName]: moment(lastElement[datetimeName])
            .add(
              1,
              this.interval == this.$enums.ChartInterval.Day.Value
                ? "hours"
                : "days"
            )
            .format(),
          ...value,
        });
      }
    },
  },
  async mounted() {
    this.chartType = this.ChartType;
    this.interval = this.Interval;
    this.setInterval();
    await this.getData();
  },
};
</script>

<style scoped>
@import '../style.css';
.chart-title {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: var(--eh-secondary-5);
  font-size: 23px;
}
</style>
